import React from "react"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import Grid from "@material-ui/core/Grid"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ArrowDownIcon from "@material-ui/icons/ArrowDownwardRounded"
import Tooltip from "@material-ui/core/Tooltip"
import BrandIcon from "@material-ui/icons/LocalOfferRounded"
import CheckIcon from "@material-ui/icons/CheckRounded"
import ClearIcon from "@material-ui/icons/ClearRounded"
import NumberFormat from "react-number-format"

import { titleCase } from "../../util/strings"
import Image from "../img/Image"

import "./FactorInput.sass"
import { EUR_SYMBOL } from "../../util/constants"

function NumberFormatCustom(props) {
  const {
    inputRef,
    onChange,
    prefix,
    suffix,
    decimalScale = 4,
    limit = 1000,
    ...other
  } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      thousandSeparator
      allowedDecimalSeparators={[","]}
      allowNegative={false}
      fixedDecimalScale
      decimalScale={decimalScale}
      prefix={prefix}
      suffix={suffix}
      isAllowed={(values) => {
        const { floatValue } = values
        return floatValue < limit
      }}
    />
  )
}

function FactorInput({
  style,
  className,
  productCurrency = EUR_SYMBOL,
  productName,
  productPicture,
  productPrice,
  productBrand,
  productUrl,
  matchName,
  matchPicture,
  matchPrice,
  matchBrand,
  matchUrl,
  factor,
  open,
  calcExpanded,
  calcUnit,
  productMultiplier,
  productContent,
  matchCurrency = EUR_SYMBOL,
  matchMultiplier,
  matchContentValue,
  calculatedFactor,
  isNullFactor = false,
  adjustedMatchPrice,
  readOnly,
  // events
  onOpen,
  onClose,
  onCalcHeaderClick,
  onProductMultiplierChange,
  onProductContentChange,
  onMatchMultiplierChange,
  onMatchContentValueChange,
  onFactorInputChange,
  onFactorInputKeyPress,
  onUseCalculatedFactor,
  onApply,
}) {
  const matchBrandText = matchBrand ? titleCase(matchBrand) : "Unknown"
  const productBrandText = productBrand ? titleCase(productBrand) : "Unknown"
  const productCurrencyElement = <span className="currency">{productCurrency}</span>
  const matchCurrencyElement = <span className="currency">{matchCurrency}</span>
  let enforcedFactor = factor
  if (isNullFactor) {
    enforcedFactor = 1
  }

  const commonInputProps = {
    className: "form-control",
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      inputComponent: NumberFormatCustom,
      className: "factor-calc-input",
    },
  }

  // eslint-disable-next-line react/prop-types
  const ReadOnlyWrapper = ({ showReadOnly = false, children }) => {
    if (!showReadOnly) {
      return <>{children}</>
    }
    return (
      <Tooltip
        title={
          <Typography variant="subtitle2" style={{ color: "white" }}>
            Please notice that the product must be matched before the Multiplication
            Factor (MF) can be changed.
          </Typography>
        }
        placement="top"
      >
        {children}
      </Tooltip>
    )
  }

  return (
    <span style={style} className={className}>
      <ReadOnlyWrapper showReadOnly={readOnly}>
        <span>
          <Button
            className="factor-button"
            variant="outlined"
            color="default"
            size="small"
            onClick={onOpen}
            title="Edit Match Factor"
            disabled={readOnly}
          >
            {enforcedFactor.toFixed(4)}
          </Button>
        </span>
      </ReadOnlyWrapper>
      {isNullFactor && <span id="empty-factor" />}
      <Dialog
        className="dialog factor-dialog"
        scroll="paper"
        open={open}
        fullWidth
        onClose={onClose}
      >
        <DialogTitle className="title">Edit Match Factor</DialogTitle>
        <DialogContent data-testid="match-factor-edit-content-root">
          <Grid container spacing={16}>
            <Grid item md={5} xs={12} className="flex v center">
              <Typography
                component="span"
                className="brand"
                title={`Brand: ${productBrandText}`}
              >
                <BrandIcon fontSize="inherit" className="brand-icon" />
                {productBrandText}
              </Typography>
              <span className="product-name">
                <a
                  href={productUrl}
                  target="product_source"
                  title={
                    productUrl
                      ? "Click to see the product on the website"
                      : titleCase(productName)
                  }
                >
                  {titleCase(productName)}
                </a>
              </span>
              <Image className="picture" src={productPicture} key={productPicture} />
            </Grid>
            <Grid item md={2} xs={12} className="center centered">
              <div className="vertical-separator" />
            </Grid>
            <Grid item md={5} xs={12} className="flex v center">
              <Typography
                component="span"
                className="brand"
                title={`Brand: ${matchBrandText}`}
              >
                <BrandIcon fontSize="inherit" className="brand-icon" />
                {matchBrandText}
              </Typography>
              <span className="product-name">
                <a
                  href={matchUrl}
                  target="match_source"
                  title={
                    matchUrl
                      ? "Click to see the product on the website"
                      : titleCase(matchName)
                  }
                >
                  {titleCase(matchName)}
                </a>
              </span>
              <Image className="picture" src={matchPicture} key={matchPicture} />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <ExpansionPanel expanded={calcExpanded} className="calculator">
              <ExpansionPanelSummary
                onClick={onCalcHeaderClick}
                className="header"
                classes={{
                  expanded: "calc-expanded",
                  content: "content",
                }}
              >
                <Typography className="title">
                  Factor Calculator
                  <ExpandMoreIcon className="expand-icon" />
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="flex v">
                <Grid container spacing={0}>
                  <Grid item md={5} sm={12} className="calc-fields-area">
                    <TextField
                      {...commonInputProps}
                      id="txt-product-multiplier"
                      label="Quantity"
                      title="The reference product's quantity"
                      value={productMultiplier}
                      onChange={({ target: { value } }) =>
                        onProductMultiplierChange(value)
                      }
                    />
                    <span className="mult-symbol">x</span>
                    <TextField
                      {...commonInputProps}
                      id="txt-product-content-value"
                      label={`Un. (${calcUnit || "?"})`}
                      title={`Reference product's content value ${
                        calcUnit ? `in ${calcUnit}` : ""
                      }`}
                      value={productContent}
                      onChange={({ target: { value } }) =>
                        onProductContentChange(value)
                      }
                    />
                  </Grid>
                  <Grid item md={2} sm={false} />
                  <Grid item md={5} sm={12} className="calc-fields-area">
                    <TextField
                      {...commonInputProps}
                      id="txt-match-multiplier"
                      label="Quantity"
                      title="The match's quantity"
                      value={matchMultiplier}
                      onChange={({ target: { value } }) =>
                        onMatchMultiplierChange(value)
                      }
                    />
                    <span className="mult-symbol">x</span>
                    <TextField
                      {...commonInputProps}
                      id="txt-match-content-value"
                      label={`Un. (${calcUnit || "?"})`}
                      title={`Match's content value ${
                        calcUnit ? `in ${calcUnit}` : ""
                      }`}
                      value={matchContentValue}
                      onChange={({ target: { value } }) =>
                        onMatchContentValueChange(value)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="flex h center">
                  <Typography className="calculated-factor">
                    Suggested Factor:
                    {calculatedFactor && (
                      <>
                        <button
                          className="link-button use-this-link"
                          title="Click to use this"
                          type="button"
                          onClick={onUseCalculatedFactor}
                        >
                          <span className="value">{calculatedFactor.toFixed(4)}</span>
                        </button>
                        <Button
                          size="small"
                          variant="outlined"
                          title="Click to use the suggested factor"
                          onClick={onUseCalculatedFactor}
                        >
                          <ArrowDownIcon fontSize="inherit" />
                          use
                        </Button>
                      </>
                    )}
                    {!calculatedFactor && (
                      <button
                        className="link-button unknown-calculated-value"
                        type="button"
                      >
                        <span className="value">N/A</span>
                      </button>
                    )}
                  </Typography>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>

          <Grid container spacing={16}>
            <Grid item md={5} xs={12} className="flex v product">
              <table className="text-right">
                <tbody>
                  <tr className="read-only-field">
                    <td className="label">Price:</td>
                    <td className="value">
                      {productPrice !== undefined ? (
                        <>
                          {productCurrencyElement}
                          {productPrice.toFixed(2)}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item md={2} xs={12} className="center centered">
              <div className="vertical-separator" />
            </Grid>
            <Grid item md={5} xs={12} className="flex v center factor-adusting-area">
              <table className="text-right">
                <tbody>
                  <tr
                    className={`read-only-field ${
                      enforcedFactor !== 1 && matchPrice ? "overwritten" : ""
                    }`}
                  >
                    <td className="label">Price:</td>
                    <td className="value">
                      {matchPrice !== undefined ? (
                        <>
                          {matchCurrencyElement}
                          {matchPrice.toFixed(2)}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr className="read-only-field">
                    <td className="label">Factor:</td>
                    <td style={{ maxWidth: "8rem" }}>
                      <TextField
                        id="txt-factor"
                        className="form-control factor-input"
                        title="The multiplication factor to be applied on the match price"
                        autoFocus
                        value={enforcedFactor}
                        onChange={({ target: { value } }) => onFactorInputChange(value)}
                        onKeyPress={onFactorInputKeyPress}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: { prefix: "x ", decimalScale: 4 },
                          className: "factor-s",
                        }}
                      />
                    </td>
                  </tr>
                  <tr
                    className={`read-only-field ${
                      enforcedFactor === 1 || !matchPrice ? "no-opacity" : ""
                    }`}
                  >
                    <td className="label">Adjusted:</td>
                    <td className="value">
                      {adjustedMatchPrice !== undefined ? (
                        <>
                          {matchCurrencyElement}
                          {adjustedMatchPrice.toFixed(2)}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="actions">
          <Button
            className="button"
            onClick={onClose}
            variant="contained"
            color="secondary"
          >
            <ClearIcon />
            Cancel
          </Button>
          <Button
            className="button"
            onClick={onApply}
            variant="contained"
            color="primary"
          >
            <CheckIcon /> Apply
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  )
}

export default FactorInput
