import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import debounce from "lodash/debounce"

import mixpanel from "mixpanel-browser"
import ReferenceProductSliderPresenter from "./ReferenceProductSliderPresenter"
import { PAGE_SIZE } from "../../ducks/reference-products/common"
import { actionCreators as productActions } from "../../ducks/reference-products"
import { Logger } from "../../util/log"
import { NEW, DONE, SKIPPED } from "../../util/statuses"
import { PRODUCT_SUGGESTIONS_LIMIT } from "../../util/constants"
import { getPicture } from "../../util/product"
import { FEAT_SUGGEST_IMAGES } from "../../util/features"
import useFeatures from "../../hooks/use-features"

const log = new Logger("ui:ReferenceProductSlider")

let searchTermChanged = false

function preSuggest(action, searchChange, term, limit) {
  if (searchChange) {
    action(term, limit)
  }
}

const debouncedListSuggestions = debounce(preSuggest, 500)
const features = [FEAT_SUGGEST_IMAGES]

function ReferenceProductSlider({
  selectedL1Categories,
  selectedL2Categories,
  selectedL3Categories,
  selectedL4Categories,
  autoCompleteSuggestions,
  suggestionsTotal,
  productIndex,
  product,
  referenceProducts,
  referenceProductsQuery,
  list,
  offset,
  prevPageList,
  nextPageList,
  listError,
  productError,
  loadingProductPages,
  page,
  total,
  navDirection,
  selectedStatus,
  sortBy,
  countries,
  families,
  selectedCountries,
  selectedFamilies,
  // actions
  listReferenceProducts,
  listReferenceProductAutoCompleteSuggestions,
  getProductsByIds,
  getSuggestedPictures,
  filterReferenceProductsByCountry,
  filterReferenceProductsByFamily,
  filterReferenceProductsById,
  startFilterReferenceProductsByL1Categories,
  startFilterReferenceProductsByL2Categories,
  startFilterReferenceProductsByL3Categories,
  startFilterReferenceProductsByL4Categories,
  filterReferenceProductsByQuery,
  sortReferenceProductsBy,
  startSelectReferenceProduct,
}) {
  const statusList = [NEW, DONE, SKIPPED]

  const [searchTerm, setSearchTerm] = useState(referenceProductsQuery)

  const [suggestImages = false] = useFeatures({ names: features })

  const lastPage = page * PAGE_SIZE >= total

  log.debug(
    "lastPage:",
    lastPage,
    "page:",
    page,
    "PAGE_SIZE:",
    PAGE_SIZE,
    "total:",
    total,
  )

  function handleCountryFilterChange({ target: { value } }) {
    log.debug("changing country filter. value:", value)
    filterReferenceProductsByCountry(value)
  }

  function handleFamilyFilterChange({ target: { value } }) {
    log.debug("changing family filter. value:", value)
    filterReferenceProductsByFamily(value)
  }

  function handleSearchTermChange(_, { newValue, method }) {
    if (method !== "enter") {
      setSearchTerm(newValue)
      searchTermChanged = true
    }
  }

  function handleSuggestionsFetchRequested({ value }) {
    debouncedListSuggestions(
      listReferenceProductAutoCompleteSuggestions,
      searchTermChanged,
      value,
      PRODUCT_SUGGESTIONS_LIMIT,
    )
  }

  function handleSuggestionsClearRequested() {
    if (referenceProductsQuery) {
      filterReferenceProductsByQuery()
    }
    searchTermChanged = false
    debouncedListSuggestions.cancel()
  }

  function handleSuggestionSelected(_, { suggestionValue, source, modeChanged }) {
    log.debug("suggestionValue:", suggestionValue, source)

    searchTermChanged = false
    debouncedListSuggestions.cancel()

    if (referenceProductsQuery !== suggestionValue || modeChanged) {
      filterReferenceProductsByQuery(suggestionValue, { source, modeChanged })
    }
  }

  function loadMissing(fullList, message) {
    const missing = fullList.filter((id) => !referenceProducts[id])

    if (missing.length > 0) {
      log.debug("loading missing products:", missing)
      getProductsByIds({ ids: missing, message, preLoadImages: true })
    }
  }

  function handleSelectProduct(index) {
    startSelectReferenceProduct(index)
  }

  function handleSortByChange({ target: { value } }) {
    mixpanel.track("Applied Sort By", { value })

    sortReferenceProductsBy(value)
  }

  function handleClearFilters() {
    mixpanel.track("Clicked Clear Filters")

    filterReferenceProductsById()
    filterReferenceProductsByQuery()
    filterReferenceProductsByCountry()
    filterReferenceProductsByFamily()
    startFilterReferenceProductsByL1Categories()
    startFilterReferenceProductsByL2Categories()
    startFilterReferenceProductsByL3Categories()
    startFilterReferenceProductsByL4Categories()
    startSelectReferenceProduct()
    setSearchTerm("")
  }

  const productId = list[productIndex - offset]
  const prevPage = page - 1
  const nextPage = page + 1
  const filterHash =
    selectedL1Categories.toString() +
    selectedL2Categories.toString() +
    selectedL3Categories.toString() +
    selectedL4Categories.toString() +
    referenceProductsQuery

  log.debug("productIndex:", productIndex, "productId:", productId, "product:", product)
  useEffect(() => {
    log.debug(
      "productIndex:",
      productIndex,
      "productId:",
      productId,
      "product:",
      product,
    )

    if (productIndex !== undefined) {
      if (
        page > 1 &&
        prevPageList.length === 0 &&
        !loadingProductPages.includes(prevPage)
      ) {
        listReferenceProducts(prevPage, "prevPageList")
      }

      if (prevPageList.length > 0 && !referenceProducts[prevPageList[PAGE_SIZE - 1]]) {
        loadMissing(prevPageList, "prev page products")
      }

      if (!product) {
        loadMissing(list, "Reference (main page) products")
      }

      if (
        page &&
        total &&
        !lastPage &&
        nextPageList.length === 0 &&
        !loadingProductPages.includes(nextPage)
      ) {
        listReferenceProducts(nextPage, "nextPageList")
      }

      if (
        page &&
        total &&
        nextPageList.length > 0 &&
        !referenceProducts[nextPageList[0]]
      ) {
        loadMissing(nextPageList, "next page products")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIndex, productId, filterHash])

  useEffect(() => {
    if (suggestImages) {
      log.debug("PRODUCT >>>> ", product)
      if (product && !getPicture(product) && !product.suggestedPictures) {
        log.debug("Loading suggested pictures")
        getSuggestedPictures(product)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, product !== undefined])

  const needPrevPage = Math.floor((productIndex - 1) / PAGE_SIZE) < page - 1
  const enablePrevItem =
    productIndex > 0 &&
    (!needPrevPage ||
      (prevPageList.length > 0 && !loadingProductPages.includes(prevPage)))
  const needNextPage = Math.floor((productIndex + 1) / PAGE_SIZE) >= page
  const enableNextItem =
    productIndex < total - 1 &&
    (!needNextPage ||
      (nextPageList.length > 0 && !loadingProductPages.includes(nextPage)))

  const slideDirection = navDirection === "forward" ? "left" : "right"
  const skipped = selectedStatus === "skipped"

  const loading =
    loadingProductPages.includes(page) ||
    (total === undefined &&
      productIndex === undefined &&
      !listError &&
      !productError) ||
    (total > 0 && !product && !listError && !productError)

  function goToNextItem() {
    const next = productIndex + 1

    mixpanel.track("MT - Make Matches Next Item", {
      productIndex: next,
    })

    if (enableNextItem) {
      handleSelectProduct(next)
    }
  }

  function goToPreviousItem() {
    const previous = productIndex - 1

    mixpanel.track("MT - Make Matches Previous Item", {
      productIndex: previous,
    })

    if (enablePrevItem) {
      handleSelectProduct(previous)
    }
  }

  return (
    <ReferenceProductSliderPresenter
      suggestions={autoCompleteSuggestions}
      suggestionsTotal={suggestionsTotal}
      page={page}
      index={productIndex}
      total={total}
      product={product}
      availableCountries={countries}
      availableFamilies={families}
      selectedCountries={selectedCountries}
      selectedFamilies={selectedFamilies}
      statusList={statusList}
      selectedStatus={selectedStatus}
      slideDirection={slideDirection}
      skipped={skipped}
      listError={listError}
      productError={productError}
      loading={loading}
      searchTerm={searchTerm}
      sortBy={sortBy}
      onClearFilters={handleClearFilters}
      onSortByChange={handleSortByChange}
      onCountryFilterChange={handleCountryFilterChange}
      onFamilyFilterChange={handleFamilyFilterChange}
      onSearchTermChange={handleSearchTermChange}
      onPrev={goToPreviousItem}
      onNext={goToNextItem}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      onSuggestionSelected={handleSuggestionSelected}
    />
  )
}

const mapStateToProps = ({
  refProduct: {
    detailedProductIndex,
    autoCompleteSuggestions,
    suggestions,
    suggestionsTotal,
    productCount,
    list = [],
    prevPageList,
    nextPageList,
    listError,
    productError,
    loadingProductPages,
    skipping,
    restoring,
    referenceProducts,
    referenceProductsQuery,
    offset,
    page,
    navDirection,
    referenceProductsStatus,
    selectedCountries,
    selectedFamilies,
    sortBy,
    summary: { countries, families } = {},
    selectedL1Categories,
    selectedL2Categories,
    selectedL3Categories,
    selectedL4Categories,
  },
  router: { location },
}) => ({
  autoCompleteSuggestions,
  suggestions,
  suggestionsTotal,
  total: productCount,
  productIndex: detailedProductIndex,
  product:
    detailedProductIndex !== undefined
      ? referenceProducts[list[detailedProductIndex - offset]]
      : undefined,
  referenceProducts,
  referenceProductsQuery,
  list,
  listError,
  productError,
  offset,
  prevPageList,
  nextPageList,
  loadingProductPages,
  skipping,
  restoring,
  page,
  navDirection,
  sortBy,
  selectedStatus: referenceProductsStatus,
  selectedL1Categories,
  selectedL2Categories,
  selectedL3Categories,
  selectedL4Categories,
  location,
  countries,
  families,
  selectedCountries,
  selectedFamilies,
})

const mapDispatchToProps = {
  ...productActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceProductSlider)
