import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Divider,
  Icon,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import "./nav-logo.scss"

function AccountMenu({
  user,
  selectedAccount,
  selectedCountry,
  customers: customersList,
  onLogout,
  onClose,
  onSelectCustomer,
  open,
  anchorEl,
}) {
  const theme = useTheme()
  const [isSelectOpen, setSelectOpen] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [customers, setCustomers] = useState(customersList)
  const timerRef = useRef()
  const { t } = useTranslation()

  const filterCustomers = (search) => {
    const filteredCustomers = customersList.filter((customer) =>
      customer.name.toLowerCase().includes(search.toLowerCase()),
    )
    setCustomers(filteredCustomers)
  }

  const searchText = (e) => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      filterCustomers(e.target.value)
    }, 150)
  }

  const closeSelect = () => {
    setSelectOpen(false)
    setAnchor(null)
    // reset filter
    searchText({ target: { value: "" } })
  }

  const openSelect = (event) => {
    setAnchor(event.currentTarget)
    setSelectOpen(true)
  }

  const handleIllowWidget = () => {
    window.illow.showWidget()
    document.getElementById("illow-banner-widget").style.display = ""
  }

  const SelectCustomerMenu = () => (
    <Menu
      anchorEl={anchor}
      id="select-account-menu"
      open={isSelectOpen}
      onClose={closeSelect}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{ maxHeight: 496 }}
      slotProps={{
        paper: {
          sx: {
            backgroundColor: theme.palette.grey[100],
          },
        },
      }}
      variant="menu"
    >
      <Box
        sx={{
          backgroundColor: theme.palette.grey[100],
          cursor: "default",
          padding: 1,
          position: "sticky",
          top: 0,
          zIndex: 1,
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <TextField
          fullWidth
          id="search-customer"
          label={t("Search")}
          onChange={searchText}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon className="material-symbols-outlined">search</Icon>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Box>
      <Box sx={{ overflowY: "auto" }}>
        {customers.map((customer) => (
          <MenuItem key={customer.code} onClick={() => onSelectCustomer(customer.code)}>
            {customer.name}
            <Typography
              variant="caption"
              sx={{ marginLeft: 1, textTransform: "uppercase" }}
            >
              ({customer.country})
            </Typography>
          </MenuItem>
        ))}
        {customers.length === 0 && <MenuItem disabled>No options</MenuItem>}
      </Box>
    </Menu>
  )

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={onClose}
        // onClick={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              backgroundColor: theme.palette.grey[100],
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              // arrow
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                bottom: 8,
                left: -5,
                width: 10,
                height: 10,
                bgcolor: theme.palette.grey[100],
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        sx={{
          top: -8,
        }}
      >
        <MenuItem disableRipple sx={{ cursor: "default" }}>
          <ListItemText
            primary={
              <Typography variant="body2" sx={{ opacity: 0.6 }}>
                {t("User")}
              </Typography>
            }
            secondary={
              <Typography variant="body1" sx={{ opacity: 0.87 }}>
                {user}
              </Typography>
            }
            sx={{ display: "flex", flexDirection: "column" }}
          />
        </MenuItem>
        <MenuItem disableRipple sx={{ cursor: "default" }}>
          <ListItemText
            primary={
              <Typography variant="body2" sx={{ opacity: 0.6 }}>
                {t("Account")}
              </Typography>
            }
            secondary={
              <Typography variant="body1" sx={{ opacity: 0.87 }}>
                {selectedAccount}
                <Typography
                  variant="caption"
                  sx={{ marginLeft: 1, textTransform: "uppercase" }}
                >
                  ({selectedCountry})
                </Typography>
              </Typography>
            }
            sx={{ display: "flex", flexDirection: "column" }}
          />
        </MenuItem>
        <Divider sx={{ mx: 1 }} />
        <MenuItem onClick={handleIllowWidget}>
          <ListItemIcon>
            <Icon className="material-symbols-outlined">cookie</Icon>
          </ListItemIcon>
          {t("Cookies")}
        </MenuItem>
        {customersList.length > 1 && (
          <MenuItem onClick={openSelect}>
            <ListItemIcon>
              <Icon className="material-symbols-outlined">switch_account</Icon>
            </ListItemIcon>
            {t("Switch account")}
            <ListItemIcon sx={{ justifyContent: "flex-end", flexGrow: 1 }}>
              <Icon className="material-symbols-rounded" sx={{ fontSize: "1em" }}>
                arrow_forward_ios
              </Icon>
            </ListItemIcon>
          </MenuItem>
        )}
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <Icon className="material-symbols-outlined">logout</Icon>
          </ListItemIcon>
          {t("Sign out")}
        </MenuItem>
      </Menu>
      {SelectCustomerMenu()}
    </>
  )
}

AccountMenu.propTypes = {
  selectedAccount: PropTypes.string.isRequired,
  selectedCountry: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onLogout: PropTypes.func,
  onSelectCustomer: PropTypes.func,
  open: PropTypes.bool.isRequired,
  customers: PropTypes.arrayOf(Object),
  user: PropTypes.string.isRequired,
}

AccountMenu.defaultProps = {
  onLogout: () => {},
  onClose: () => {},
  onSelectCustomer: () => {},
  customers: [],
}

export { AccountMenu }
