import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"
import { Badge, Box, Icon, Link, Tooltip } from "@mui/material"
import styles from "./ArticleDetailEntry.module.scss"

export function ArticleDetailEntry({
  title,
  value,
  slot,
  debug,
  unsure,
  unvalidated,
  endNode,
  link,
}) {
  const handleLink = (text, url) => {
    if (!url) {
      return text
    }
    return (
      <Link
        href={url}
        target="_blank"
        rel="noopener"
        sx={{ alignItems: "center", display: "flex" }}
        underline="hover"
      >
        <Icon className="material-symbols-outlined">link</Icon> {text}
      </Link>
    )
  }

  return (
    <div className={styles.container}>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
        <Tooltip
          title={
            unvalidated ? "Unvalidated attribute" : unsure ? "Unsure attribute" : ""
          }
          placement="right"
        >
          <Badge
            color={unvalidated ? "error" : unsure ? "ochre" : "none"}
            variant="dot"
            invisible={!unsure && !unvalidated}
            sx={{ paddingRight: 1 }}
          >
            <h1 className={styles.title}>{title}</h1>
          </Badge>
        </Tooltip>
        {endNode}
      </Box>
      <div className={styles.content}>
        {slot}
        <p className={clsx(styles.value, debug && styles.debug)}>
          {handleLink(value, link)}
        </p>
      </div>
    </div>
  )
}

ArticleDetailEntry.propTypes = {
  title: PropTypes.string.isRequired,
  slot: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  debug: PropTypes.bool,
  unsure: PropTypes.bool,
  unvalidated: PropTypes.bool,
  endNode: PropTypes.node,
  link: PropTypes.string,
}

ArticleDetailEntry.defaultProps = {
  slot: null,
  value: "",
  debug: false,
  unsure: false,
  unvalidated: false,
  endNode: null,
  link: "",
}
