import _PropTypes from "prop-types"
import React from "react"
import { IconAlertCircleFilled, IconPackage } from "@tabler/icons-react"

import { useDispatch } from "react-redux"
import MatchesOverviewControl from "./MatchesOverviewControl"
import { Toggle } from "../ui"
import * as Action from "../../ducks/pages/matches-overview/action-creators"
import useMatchesOverviewFilters from "./hooks/use-matches-overview-filters"
import useMatchesOverviewReferenceProductFamilies from "./hooks/use-matches-overview-reference-product-families"
import useMatchesOverviewReferenceProductCategories from "./hooks/use-matches-overview-reference-product-categories/use-matches-overview-reference-product-categories"
import MatchesOverviewFiltersButton from "./MatchesOverviewControlFiltersButton"
import styles from "./MatchesOverviewToolbar.module.scss"
import MatchesOverviewFiltersDialog from "./MatchesOverviewFiltersDialog"
import MatchesOverviewFiltersCheckboxList from "./MatchesOverviewFiltersCheckboxList"
import MatchesOverviewFieldset from "./MatchesOverviewFieldset"
import useMatchesOverviewFiltersClear from "./hooks/use-matches-overview-filters-clear"
import useMatchesOverviewFiltersControlledApply from "./hooks/use-matches-overview-filters-controlled-apply"
import useMatchesOverviewFiltersUnstage from "./hooks/use-matches-overview-filters-unstage"
import { integerToRoman } from "../../util/number"
import useMatchesOverviewMatchCountries from "./hooks/use-matches-overview-match-countries"
import useMatchesOverviewMatchShops from "./hooks/use-matches-overview-match-shops"
import useMatchesOverviewReferenceProductSearch from "./hooks/use-matches-overview-reference-product-search"
import { MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY } from "../../ducks/pages/matches-overview/constants"
import MatchesOverviewMatchStatusToggleGroup from "./MatchesOverviewMatchStatusToggleGroup"
import { buildAnalyticsEventName } from "../../util/user-monitoring"
import { toLevelArrays } from "./hooks/use-matches-overview-reference-product-categories/to-level-arrays"
import { ReferenceProductSearch } from "../reference-product-search"
import { getValuesOfSearchParams } from "../../ducks/pages/matches-overview/helpers"

function ToggleReferenceProductsWithoutMatchesFilter() {
  const dispatch = useDispatch()

  const referenceProductMatchesCount = useMatchesOverviewFilters([
    MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
    "count",
  ])

  const isToggled = referenceProductMatchesCount === 0
  const tooltip = "Toggle only reference products without matches"

  function toggle() {
    dispatch(Action.toggleFilterReferenceProductsWithoutMatches())
  }

  return (
    <Toggle
      analyticsEventName="MT - Matches Overview Filter Reference Products Without Matches Clicked"
      defaultPressed={isToggled}
      onClick={toggle}
      pressed={isToggled}
      tooltip={tooltip}
    >
      <div>
        <span className="sr-only">{tooltip}</span>
        <IconPackage size={24} color="#bbb" />
        <div className="relative">
          <IconAlertCircleFilled
            size={16}
            color="#555555"
            style={{
              position: "absolute",
              bottom: "0px",
              right: "-5px",
              background: "#ffffff",
              borderRadius: "9999px",
            }}
          />
        </div>
      </div>
    </Toggle>
  )
}

function createAnalyticsEventName(fieldsetTitle, group) {
  return buildAnalyticsEventName({
    feature: "Matches Overview",
    target: `${fieldsetTitle} Filter (${group} Dialog)`,
    userAction: "Clicked",
  })
}

function MatchesOverviewToolbar() {
  const cancelFilterChanges = useMatchesOverviewFiltersUnstage()
  const applyFilters = useMatchesOverviewFiltersControlledApply()

  /* -------------------------------------------------------------------------- */
  /*                                    state                                   */
  /* -------------------------------------------------------------------------- */
  const { clearMatchFilters, clearReferenceProductFilters } =
    useMatchesOverviewFiltersClear()

  const { appliedFamilies, families, setFamilies } =
    useMatchesOverviewReferenceProductFamilies()
  const { appliedCategories, categories, setCategories, groupCategoriesByAncestor } =
    useMatchesOverviewReferenceProductCategories()

  const { clearSearch, currentSearch, selectSearch } =
    useMatchesOverviewReferenceProductSearch({
      appliedCategories,
      appliedFamilies,
    })

  const filters = {}
  if (appliedCategories.length > 0) {
    Object.assign(filters, toLevelArrays(getValuesOfSearchParams(appliedCategories)))
  }
  if (appliedFamilies.length > 0) {
    Object.assign(filters, { families: getValuesOfSearchParams(appliedFamilies) })
  }

  const { appliedCountries, countries, setCountries } =
    useMatchesOverviewMatchCountries()
  const { appliedShops, shops, setShops } = useMatchesOverviewMatchShops()

  /* -------------------------------------------------------------------------- */
  /*                               computed state                               */
  /* -------------------------------------------------------------------------- */
  const productFiltersVisible = families.length > 0 || categories.length > 0
  const productAppliedFilterSum = appliedCategories.length + appliedFamilies.length

  const matchFiltersVisible = countries.length > 0 || shops.length > 0
  const matchAppliedFilterSum = appliedCountries.length + appliedShops.length

  return (
    <div className={styles["toolbar-root"]} data-testid="matches-overview-toolbar">
      <ul className={styles["control-list"]} style={{ justifyContent: "start" }}>
        {productFiltersVisible && (
          <MatchesOverviewControl>
            <MatchesOverviewFiltersDialog
              onApply={applyFilters}
              onClear={clearReferenceProductFilters}
              onClose={cancelFilterChanges}
              title="Reference Product Filters"
              trigger={
                <MatchesOverviewFiltersButton
                  activeFilterCount={productAppliedFilterSum}
                >
                  Reference Product Filters
                </MatchesOverviewFiltersButton>
              }
            >
              {families.length > 0 && (
                <MatchesOverviewFieldset title="Labels">
                  <MatchesOverviewFiltersCheckboxList
                    analyticsEventName={createAnalyticsEventName(
                      "Labels",
                      "Reference Product",
                    )}
                    items={families}
                    onToggle={setFamilies}
                  />
                </MatchesOverviewFieldset>
              )}
              {categories.length > 0 &&
                categories.map((level, index) => {
                  const title = `Categories ${integerToRoman(index + 1)}`

                  return level.length > 0 ? (
                    <MatchesOverviewFieldset title={title} key={level.toString()}>
                      <MatchesOverviewFiltersCheckboxList
                        analyticsEventName={createAnalyticsEventName(
                          title,
                          "Reference Product",
                        )}
                        groupBy={groupCategoriesByAncestor}
                        items={level}
                        onToggle={setCategories}
                      />
                    </MatchesOverviewFieldset>
                  ) : null
                })}
            </MatchesOverviewFiltersDialog>
          </MatchesOverviewControl>
        )}
        <MatchesOverviewControl grows iconSlot="start" justify="start">
          <ReferenceProductSearch
            filters={filters}
            onSelect={({ query, source, modeChanged }) => {
              if (!query) {
                clearSearch()
                return
              }
              selectSearch({
                title: query,
                type: source,
                modeChanged,
              })
            }}
            value={currentSearch?.title}
            sx={{ py: "8px !important", pl: "8px !important" }}
          />
        </MatchesOverviewControl>
        {matchFiltersVisible && (
          <MatchesOverviewControl>
            <MatchesOverviewFiltersDialog
              onApply={applyFilters}
              onClear={clearMatchFilters}
              onClose={cancelFilterChanges}
              title="Match Filters"
              trigger={
                <MatchesOverviewFiltersButton activeFilterCount={matchAppliedFilterSum}>
                  Match Filters
                </MatchesOverviewFiltersButton>
              }
            >
              {countries.length > 0 && (
                <MatchesOverviewFieldset title="Retailer countries">
                  <MatchesOverviewFiltersCheckboxList
                    analyticsEventName={createAnalyticsEventName(
                      "Retailer Countries",
                      "Matches",
                    )}
                    items={countries}
                    onToggle={setCountries}
                  />
                </MatchesOverviewFieldset>
              )}
              {shops.length > 0 && (
                <MatchesOverviewFieldset title="Retailers">
                  <MatchesOverviewFiltersCheckboxList
                    analyticsEventName={createAnalyticsEventName("Retailer", "Matches")}
                    items={shops}
                    onToggle={setShops}
                  />
                </MatchesOverviewFieldset>
              )}
            </MatchesOverviewFiltersDialog>
          </MatchesOverviewControl>
        )}
        <MatchesOverviewControl>
          <ToggleReferenceProductsWithoutMatchesFilter />
        </MatchesOverviewControl>
        <MatchesOverviewControl>
          <MatchesOverviewMatchStatusToggleGroup />
        </MatchesOverviewControl>
      </ul>
    </div>
  )
}

MatchesOverviewToolbar.propTypes = {}

MatchesOverviewToolbar.defaultProps = {}

export default MatchesOverviewToolbar
