import { handleUpdateSubCategories, handleSelectCategories } from "../../category"

export const handleFilterReferenceProductsByStatus = (state, { status }) => ({
  ...state,
  referenceProductsStatus: status,
})

export const handleFilterReferenceProductsByCountry = (state, { countries }) => ({
  ...state,
  selectedCountries: countries,
})

export const handleFilterReferenceProductsByFamily = (state, { families }) => ({
  ...state,
  selectedFamilies: families,
})

export const handleFilterReferenceProductsByQuery = (
  state,
  { query = "", source, modeChanged },
) => {
  const { referenceProductsQuery: prevQuery = "" } = state

  if (query !== prevQuery || modeChanged) {
    return {
      ...state,
      suggestions: [],
      autoCompleteSuggestions: [],
      suggestionsTotal: 0,
      referenceProductsQuery: query,
      referenceProductsQueryChanged: true,
      source,
    }
  }

  return {
    ...state,
    referenceProductsQueryChanged: false,
  }
}

export const handleFilterReferenceProductsById = (state, { id }) => ({
  ...state,
  selectedProductId: id,
})

export const handleFilterReferenceProductsByL1Categories = (state, action) =>
  handleSelectCategories(1, state, action)

export const handleUpdateReferenceProductsL2Categories = (state, action) =>
  handleUpdateSubCategories(2, state, action)

export const handleFilterReferenceProductsByL2Categories = (state, action) =>
  handleSelectCategories(2, state, action)

export const handleUpdateReferenceProductsL3Categories = (state, action) =>
  handleUpdateSubCategories(3, state, action)

export const handleFilterReferenceProductsByL3Categories = (state, action) =>
  handleSelectCategories(3, state, action)

export const handleUpdateReferenceProductsL4Categories = (state, action) =>
  handleUpdateSubCategories(4, state, action)

export const handleFilterReferenceProductsByL4Categories = (state, action) =>
  handleSelectCategories(4, state, action)
