import React, { useCallback, useEffect } from "react"
import { connect } from "react-redux"
import debounce from "lodash/debounce"
import { actionCreators } from "../../ducks/daltix-product"
import SearchPanelPresenter from "./SearchPanelPresenter"
import { Logger } from "../../util/log"
import { sanitizeEan } from "../../util/strings"
import { SEARCH_PAGE_SIZE } from "../../util/config"
import {
  STATUS_DISCARDED,
  STATUS_REVIEW,
  STATUS_APPROVED,
} from "../../util/match-status"
import { ORIGIN_EXISTING } from "../../util/match-origin"

const log = new Logger("ui:SearchPanel")

let typing = false

function setTyping(value) {
  typing = value
}

function SearchPanel({
  referenceProducts,
  daltixProducts,
  productId,
  searchTerm,
  suggestions,
  suggestionsTotal,
  searchResults,
  totalSearchResults,
  noFilterTotalSearchResults,
  searchInProgress,
  searchResultsMatchingStatus,
  // actions
  getSearchFilters,
  searchDxProducts,
  getDxProductsAutoCompleteSuggestions,
  setSearchTerm,
}) {
  const product = referenceProducts[productId] || {}

  function preSuggestions(term, options) {
    if (typing) {
      getDxProductsAutoCompleteSuggestions(term, options)
    }
  }

  const debouncedSuggestions = debounce(preSuggestions, 500)

  useEffect(() => () => {
    if (!typing) {
      debouncedSuggestions.cancel()
    }
  })

  function handleSearchByReferenceProductName() {
    if (searchTerm !== product.name) {
      setSearchTerm(product.name)
      getSearchFilters(product.name)
      searchDxProducts(product.name)
    }
  }

  const handleSearchTermChange = useCallback(
    (_, { newValue, method }) => {
      if (method !== "enter") {
        setSearchTerm(newValue)
        setTyping(true)
      }
    },
    [setSearchTerm],
  )

  function handleChange({ value }) {
    debouncedSuggestions(value, { pageSize: 6 })
  }

  function handleClearSuggestions() {}

  function handleSuggestionSelected(_, { suggestionValue, source }) {
    if (suggestionValue) {
      log.debug(suggestionValue)
      setTyping(false)
      debouncedSuggestions.cancel()
      getSearchFilters(searchTerm)
      searchDxProducts(suggestionValue)
    }
  }

  function handleLoadMore(page) {
    log.debug(
      "LOADING DATA PAGE.",
      `'${searchTerm}'`,
      page,
      totalSearchResults,
      SEARCH_PAGE_SIZE,
    )
    if (searchTerm && (page - 1) * SEARCH_PAGE_SIZE < totalSearchResults) {
      log.debug(searchTerm, { page, pageSize: SEARCH_PAGE_SIZE })
      searchDxProducts(searchTerm, { page, pageSize: SEARCH_PAGE_SIZE })
    }
  }

  const loadingResults = searchInProgress
  let finalSearchResults = []

  if (searchResults) {
    finalSearchResults = searchResults.map((daltixId) => {
      const matches = product.existingMatches || []
      const existing = matches.find(
        ({ daltix_id: matchId, status }) =>
          matchId === daltixId && status !== STATUS_DISCARDED,
      )

      const newMatchData = (searchResultsMatchingStatus[productId] || {})[daltixId]

      if (daltixProducts[daltixId]) {
        return {
          daltixProduct: daltixProducts[daltixId],
          // ...daltixProducts[daltixId],
          ...newMatchData,
          eanMatch:
            product.ean &&
            daltixProducts[daltixId].eans &&
            daltixProducts[daltixId].eans
              .map((ean) => sanitizeEan(ean))
              .includes(sanitizeEan(product.ean)),
          ...(existing || {}),
          daltix_id: daltixId,
          source: existing ? ORIGIN_EXISTING : undefined,
          newMatch:
            newMatchData &&
            (newMatchData.status === STATUS_REVIEW ||
              newMatchData.status === STATUS_APPROVED),
          searchResult: true,
        }
      }

      return {
        daltix_id: daltixId,
        loading: true,
      }
    })
  }

  log.debug("SEARCH_RESULT:", totalSearchResults, searchResults.length)

  return (
    <SearchPanelPresenter
      searchTerm={searchTerm}
      loadingResults={loadingResults}
      product={product}
      onSearchByReferenceProductName={handleSearchByReferenceProductName}
      onSearchTermChange={handleSearchTermChange}
      suggestions={suggestions}
      suggestionsTotal={suggestionsTotal}
      onSuggestionsFetchRequested={handleChange}
      onSuggestionsClearRequested={handleClearSuggestions}
      onSuggestionSelected={handleSuggestionSelected}
      searchResults={finalSearchResults}
      totalSearchResults={totalSearchResults}
      noFilterTotalSearchResults={noFilterTotalSearchResults}
      onLoadMoreResults={handleLoadMore}
      hasMoreResults={totalSearchResults > searchResults.length && !loadingResults}
    />
  )
}

const transformSuggestion = ({
  name: title,
  images: [picture] = [],
  eans = [],
  ...suggestion
}) => ({
  title,
  picture,
  ean: eans.join(", "),
  ...suggestion,
})

const mapStateToProps = ({
  refProduct: { referenceProducts },
  daltixProduct: {
    daltixProducts,
    autoCompleteSuggestions = [],
    totalAutoCompleteSuggestions,
    searchTerm,
    searchInProgress,
    searchResults = [],
    totalSearchResults,
    noFilterTotalSearchResults,
    searchResultsMatchingStatus,
  },
}) => ({
  referenceProducts,
  daltixProducts,
  searchTerm,
  suggestions: autoCompleteSuggestions.map(transformSuggestion),
  suggestionsTotal: totalAutoCompleteSuggestions,
  searchInProgress,
  searchResults,
  noFilterTotalSearchResults,
  totalSearchResults,
  searchResultsMatchingStatus,
})

export default connect(mapStateToProps, actionCreators)(SearchPanel)
