export const QP_AVAILABILITY = "a"
export const QP_BRAND = "brand"
export const QP_BRAND_LAYER = "brandLayer"
export const QP_CATEGORIES = "c"
export const QP_CATEGORY_1 = "c1"
export const QP_CATEGORY_2 = "c2"
export const QP_CATEGORY_3 = "c3"
export const QP_CATEGORY_4 = "c4"
export const QP_CATEGORY_5 = "c5"
export const QP_COUNTRY = "cn"
export const QP_DIMENSION = "dim"
export const QP_FAMILY = "fm"
export const QP_FACTOR = "factor"
export const QP_MATCH_TYPE = "mt"
export const QP_FLAVOUR = "flavour"
export const QP_FILTERS = "filters"
export const QP_ID = "id"
export const QP_INDEX = "i"
export const QP_MATCH_COUNT = "ref_match_count"
export const QP_MULTIPACK = "multipack"
export const QP_PACKAGING = "packaging"
export const QP_PAGE = "p"
export const QP_PAGE_SIZE = "ps"
export const QP_PRICING = "pricing"
export const QP_PRODUCT_GROUP = "pg"
export const QP_QUERY = "q"
export const QP_QUERY_FACET = "qf"
export const QP_REGULAR_ZERO = "regularZero"
export const QP_REF_PROD_COUNTRY = "ref_cn"
export const QP_REF_PROD_FAMILY = "ref_fm"
export const QP_SHOP = "shop"
export const QP_SORT = "sort"
export const QP_SOURCE = "src"
export const QP_STATUS = "st"
export const QP_STEP = "step"
export const QP_MODE = "md"
