import queryString from "query-string"
import { from, of } from "rxjs"
import {
  map,
  mergeMap,
  mergeAll,
  filter,
  withLatestFrom,
  debounceTime,
  switchMap,
  mapTo,
  take,
} from "rxjs/operators"
import { ofType } from "redux-observable"
import { push, replace, LOCATION_CHANGE } from "connected-react-router"
import { actionTypes as urlActionTypes, actionCreators as urlActions } from "../url"
import { buildEpic } from "../../util/redux-observable-helpers"
import noPicture from "../../no-image.png"
import {
  REF_PRODUCT_LIST,
  REF_PRODUCT_GET_BY_IDS,
  REF_PRODUCT_GET_BY_IDS_SUCCESS,
  // REF_PRODUCT_FILTER_BY_STATUS,
  REF_PRODUCT_FILTER_BY_QUERY,
  REF_PRODUCT_LIST_EXISTING_MATCHES,
  // REF_PRODUCT_MATCH,
  REF_PRODUCT_LIST_SUGGESTED_MATCHES_SUCCESS,
  REF_PRODUCT_LIST_EXISTING_MATCHES_SUCCESS,
  REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS,
  REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS_CANCEL,
  REF_PRODUCT_SUMMARY,
  REF_PRODUCT_TRIGGER_LIST,
  REF_PRODUCT_FILTER_BY_COUNTRY,
  REF_PRODUCT_FILTER_BY_FAMILY,
  REF_PRODUCT_FILTER_BY_ID,
  REF_PRODUCT_FILTER_BY_L1_CATEGORIES,
  REF_PRODUCT_FILTER_BY_L2_CATEGORIES,
  REF_PRODUCT_FILTER_BY_L3_CATEGORIES,
  REF_PRODUCT_FILTER_BY_L4_CATEGORIES,
  REF_PRODUCT_SORT_BY,
  REF_PRODUCT_SELECT,
  REF_PRODUCT_CLEAR_LIST,
  REF_PRODUCT_START_FILTER_BY_L1_CATEGORIES,
  REF_PRODUCT_START_FILTER_BY_L2_CATEGORIES,
  REF_PRODUCT_START_FILTER_BY_L3_CATEGORIES,
  REF_PRODUCT_START_FILTER_BY_L4_CATEGORIES,
  REF_PRODUCT_START_SELECT,
  REF_PRODUCT_LIST_SUCCESS,
  REF_PRODUCT_GET_CATEGORIES,
  REF_PRODUCT_GET_CATEGORIES_SUCCESS,
  REF_PRODUCT_UPDATE_L2_CATEGORIES,
  REF_PRODUCT_UPDATE_L3_CATEGORIES,
  REF_PRODUCT_UPDATE_L4_CATEGORIES,
} from "./action-types"
// import {
//   actionTypes as categoryActions,
// } from '../category';
import {
  useFallbackImage,
  filterReferenceProductsByCountry,
  filterReferenceProductsByQuery,
  sortReferenceProductsBy,
  clearReferenceProductsList,
  startFilterReferenceProductsByL1Categories,
  startFilterReferenceProductsByL2Categories,
  startFilterReferenceProductsByL3Categories,
  startFilterReferenceProductsByL4Categories,
  selectReferenceProduct,
  startSelectReferenceProduct,
  updateReferenceProductsL2Categories,
  updateReferenceProductsL3Categories,
  updateReferenceProductsL4Categories,
  filterReferenceProductsByL1Categories,
  filterReferenceProductsByL2Categories,
  filterReferenceProductsByL3Categories,
  filterReferenceProductsByL4Categories,
  listReferenceProducts,
  filterReferenceProductsById,
  filterReferenceProductsByFamily,
} from "./action-creators"
import { actionCreators as dxProductActions } from "../daltix-product"
import { PAGE_SIZE } from "./common"
import * as api from "../../api/service"
import { combineFilters } from "../../util/filter"
import { Logger } from "../../util/log"
import { forceArray } from "../../util/array"
import {
  QP_CATEGORY_1,
  QP_CATEGORY_2,
  QP_CATEGORY_3,
  QP_CATEGORY_4,
  QP_COUNTRY,
  QP_FAMILY,
  QP_ID,
  QP_MODE,
  QP_PAGE,
  QP_QUERY,
  QP_SORT,
} from "../../util/query-param"
import { withParent, isInPage } from "../../util/epics"
import { updateLocationQuery } from "../../util/location"

const log = new Logger("ducks:ref-product:epics")

// helper functions

/**
 * Tries to load the pictures and returns an Observable that emits the IDs of the products
 * that doesn't have an image.
 *
 * @param {Array<Object>} list The products list.
 */
const checkPictures = (list) =>
  from(
    list.map(
      (product) =>
        new Promise((resolvePromise) => {
          const url = product.picture

          let fallback = false

          const { id } = product

          const resolve = (imageExists) =>
            resolvePromise({
              id,
              product,
              imageExists,
            })

          if (url) {
            log.trace("downloading image for #", id)

            const picture = new Image()
            picture.src = url

            picture.onload = () => resolve(true)

            picture.onerror = () => {
              if (!fallback) {
                log.warn(`pic not found at: ${url}, using fallback: ${noPicture}`)
                fallback = true
                resolve(false)
              }
            }
          } else {
            resolve(true)
          }
        }),
    ),
  ).pipe(
    mergeAll(),
    filter(({ imageExists }) => !imageExists),
  )

/**
 * checks if the action product id still is part of
 * (pre)loaded products (current, previous and next)
 *
 * @param {String} id product ID.
 * @param {Object} state Current state.
 */
const validateSelectedProduct = (
  // product id
  id,
  // current state
  {
    refProduct: {
      list,
      prevPageList,
      nextPageList,
      detailedProductIndex: productIndex,
      offset,
      page,
    },
  },
) => {
  const allPagesList = [...prevPageList, ...list, ...nextPageList]

  const validIndex = productIndex !== undefined

  let productId
  let prevProductId
  let nextProductId

  if (validIndex) {
    if (list.length > 0) {
      productId = list[productIndex - offset]
    }

    if (allPagesList.length > 0) {
      prevProductId =
        allPagesList[productIndex - 1 - offset + (page > 1 ? PAGE_SIZE : 0)]
      nextProductId =
        allPagesList[productIndex + 1 - offset + (page > 1 ? PAGE_SIZE : 0)]
    }
  }

  log.debug(
    "validateSelectedProduct => id:",
    id,
    "productId:",
    productId,
    "prevProductId:",
    prevProductId,
    "nextProductId:",
    nextProductId,
  )

  return id && [prevProductId, productId, nextProductId].includes(id)
}

/**
 * Creates a function that validates if a matches request is up to date.
 *
 * @param {String} lastRequestField name of the field that stores the last request timestamp
 *
 */
const validateLastRequest = (lastRequestField) => (action, state) => {
  const {
    id,
    options: { requestedAt },
  } = action

  const {
    refProduct: { referenceProducts },
  } = state

  const product = referenceProducts[id] || {}

  const updatedRequest = requestedAt === product[lastRequestField]

  return updatedRequest
}

// epics

const getReferenceProductsSummaryEpic = buildEpic(REF_PRODUCT_SUMMARY, () =>
  api.getReferenceProductsSummary(),
)

const getReferenceProductsCategoriesEpic = buildEpic(REF_PRODUCT_GET_CATEGORIES, () =>
  api.listReferenceProductCategories(),
)

const listReferenceProductsEpic = buildEpic(
  REF_PRODUCT_LIST,
  (
    { page },
    {
      refProduct: {
        referenceProductsStatus,
        referenceProductsQuery,
        selectedCountries,
        selectedFamilies,
        selectedProductId,
        selectedL1Categories,
        selectedL2Categories,
        selectedL3Categories,
        selectedL4Categories,
        sortBy: sort,
        source,
      },
    },
  ) =>
    api.listReferenceProducts({
      page,
      sort,
      pageSize: PAGE_SIZE,
      status: referenceProductsStatus,
      countries: selectedCountries,
      families: selectedFamilies,
      ids: forceArray(selectedProductId),
      query: referenceProductsQuery,
      categories: selectedL1Categories,
      l2Categories: selectedL2Categories,
      l3Categories: selectedL3Categories,
      l4Categories: selectedL4Categories,
      source,
    }),
  undefined, // validation
  true, // override previous calls
)

const listReferenceProductAutoCompleteSuggestionsEpic = buildEpic(
  REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS,
  (
    { query, limit, source },
    {
      refProduct: {
        referenceProductsStatus,
        selectedCountries,
        selectedFamilies,
        selectedL1Categories,
        selectedL2Categories,
        selectedL3Categories,
        selectedL4Categories,
      },
    },
  ) =>
    api.getRefProdAutoCompleteSuggestions({
      query,
      source,
      countries: selectedCountries,
      families: selectedFamilies,
      limit,
      status: referenceProductsStatus,
      categories: selectedL1Categories,
      l2Categories: selectedL2Categories,
      l3Categories: selectedL3Categories,
      l4Categories: selectedL4Categories,
    }),
  (_action, { refProduct }) => refProduct.productAutoCompleteSuggestionsQueryChanged,
  true,
)

const getProductsByIdsEpic = buildEpic(REF_PRODUCT_GET_BY_IDS, ({ ids }) =>
  api.getReferenceProducts(ids.map((id) => ({ id }))),
)

const loadImagesAfterGetByIdsSuccessEpic = (action$) =>
  action$.pipe(
    ofType(REF_PRODUCT_GET_BY_IDS_SUCCESS),
    filter(({ parent: { preLoadImages } }) => preLoadImages),
    mergeMap(({ payload }) =>
      checkPictures(Object.keys(payload).map((id) => payload[id])),
    ),
    map(({ id }) => useFallbackImage(id)),
  )

const listExistingMatchesEpic = buildEpic(
  REF_PRODUCT_LIST_EXISTING_MATCHES,
  ({ id }) => api.listExistingMatches(id),
  combineFilters(
    validateLastRequest("existingMatchesLastRequest", "loadingExistingMatches"),
    ({ id }, state) => validateSelectedProduct(id, state),
  ),
)

const loadInfoAfterGetMatchesSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      REF_PRODUCT_LIST_EXISTING_MATCHES_SUCCESS,
      REF_PRODUCT_LIST_SUGGESTED_MATCHES_SUCCESS,
    ),
    withLatestFrom(state$),
    map(
      ([
        {
          payload: { matches = [] },
        },
        {
          daltixProduct: { daltixProducts },
        },
      ]) => {
        const missing = matches.filter(
          ({ daltix_id: daltixId }) => !daltixProducts[daltixId],
        )
        log.debug("missing matches info:", missing)
        return missing
      },
    ),
    filter((missing) => missing.length > 0),
    map((missing) =>
      dxProductActions.getDxProductsByIds(
        missing.map(({ daltix_id: daltixId }) => daltixId),
        "matches",
      ),
    ),
  )

const cancelQueryFilteringEpic = (action$) =>
  action$.pipe(
    ofType(REF_PRODUCT_FILTER_BY_QUERY),
    mergeMap(() =>
      from([
        {
          type: REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS_CANCEL,
        },
      ]),
    ),
  )

const startSelectReferenceProductEpic = (action$, _state$) =>
  action$.pipe(
    ofType(REF_PRODUCT_START_SELECT),
    switchMap((action) => {
      const {
        options: { fromUrl },
      } = action

      if (fromUrl) {
        return action$.pipe(ofType(REF_PRODUCT_LIST_SUCCESS), take(1), mapTo(action))
      }

      return of(action)
    }),
    map((action) => {
      const { idx, options } = action
      return withParent(action, selectReferenceProduct(idx, options))
    }),
  )

const triggerStartSelectProductAfterListReferenceProductsEpic = (action$, state$) =>
  action$.pipe(
    ofType(REF_PRODUCT_LIST_SUCCESS),
    filter(({ parent: { listName } }) => listName === "list"),
    map((action) =>
      withParent(
        action,
        startSelectReferenceProduct(state$.value.refProduct.detailedProductIndex),
      ),
    ),
  )

const startFilterReferenceProductByL1CategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(REF_PRODUCT_START_FILTER_BY_L1_CATEGORIES),
    switchMap((action) => {
      const {
        options: { fromUrl },
      } = action

      if (fromUrl) {
        return action$.pipe(
          ofType(REF_PRODUCT_GET_CATEGORIES_SUCCESS),
          take(1),
          mapTo(action),
        )
      }

      return of(action)
    }),
    map((action) => {
      const { categories, options } = action
      return withParent(
        action,
        filterReferenceProductsByL1Categories(
          categories,
          state$.value.refProduct.categories.categoriesTree,
          options,
        ),
      )
    }),
  )

const startFilterReferenceProductByL2CategoriesEpic = (action$) =>
  action$.pipe(
    ofType(REF_PRODUCT_START_FILTER_BY_L2_CATEGORIES),
    switchMap((action) => {
      const {
        options: { fromUrl },
      } = action

      if (fromUrl) {
        return action$.pipe(
          ofType(REF_PRODUCT_FILTER_BY_L1_CATEGORIES),
          take(1),
          mapTo(action),
        )
      }

      return of(action)
    }),
    map((action) => {
      const { categories, options } = action
      return withParent(
        action,
        filterReferenceProductsByL2Categories(categories, options),
      )
    }),
  )

const startFilterReferenceProductByL3CategoriesEpic = (action$) =>
  action$.pipe(
    ofType(REF_PRODUCT_START_FILTER_BY_L3_CATEGORIES),
    switchMap((action) => {
      const {
        options: { fromUrl },
      } = action

      if (fromUrl) {
        return action$.pipe(
          ofType(REF_PRODUCT_FILTER_BY_L2_CATEGORIES),
          take(1),
          mapTo(action),
        )
      }

      return of(action)
    }),
    map((action) => {
      const { categories, options } = action
      return withParent(
        action,
        filterReferenceProductsByL3Categories(categories, options),
      )
    }),
  )

const startFilterReferenceProductByL4CategoriesEpic = (action$) =>
  action$.pipe(
    ofType(REF_PRODUCT_START_FILTER_BY_L4_CATEGORIES),
    switchMap((action) => {
      const {
        options: { fromUrl },
      } = action

      if (fromUrl) {
        return action$.pipe(
          ofType(REF_PRODUCT_FILTER_BY_L3_CATEGORIES),
          take(1),
          mapTo(action),
        )
      }

      return of(action)
    }),
    map((action) => {
      const { categories, options } = action
      return withParent(
        action,
        filterReferenceProductsByL4Categories(categories, options),
      )
    }),
  )

const updateReferenceProductsL2CategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(REF_PRODUCT_FILTER_BY_L1_CATEGORIES),
    map(({ options }) =>
      updateReferenceProductsL2Categories(
        state$.value.refProduct.categories.categoriesTree,
        options,
      ),
    ),
  )

const updateReferenceProductsL3CategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(REF_PRODUCT_FILTER_BY_L2_CATEGORIES),
    map(({ options }) =>
      updateReferenceProductsL3Categories(
        state$.value.refProduct.categories.categoriesTree,
        options,
      ),
    ),
  )

const updateReferenceProductsL4CategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(REF_PRODUCT_FILTER_BY_L3_CATEGORIES),
    map(({ options }) =>
      updateReferenceProductsL4Categories(
        state$.value.refProduct.categories.categoriesTree,
        options,
      ),
    ),
  )

const filterReferenceProductsL2CategoriesAfterUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(REF_PRODUCT_UPDATE_L2_CATEGORIES),
    filter(({ options: { fromUrl } }) => !fromUrl),
    map(({ options }) =>
      filterReferenceProductsByL2Categories(
        state$.value.refProduct.selectedL2Categories,
        options,
      ),
    ),
  )

const filterReferenceProductsL3CategoriesAfterUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(REF_PRODUCT_UPDATE_L3_CATEGORIES),
    filter(({ options: { fromUrl } }) => !fromUrl),
    map(({ options }) =>
      filterReferenceProductsByL3Categories(
        state$.value.refProduct.selectedL3Categories,
        options,
      ),
    ),
  )

const filterReferenceProductsL4CategoriesAfterUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(REF_PRODUCT_UPDATE_L4_CATEGORIES),
    filter(({ options: { fromUrl } }) => !fromUrl),
    map(({ options }) =>
      filterReferenceProductsByL4Categories(
        state$.value.refProduct.selectedL3Categories,
        options,
      ),
    ),
  )

const triggerListReferenceProductsEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      REF_PRODUCT_TRIGGER_LIST, //
      REF_PRODUCT_FILTER_BY_QUERY,
      REF_PRODUCT_FILTER_BY_COUNTRY,
      REF_PRODUCT_FILTER_BY_FAMILY,
      REF_PRODUCT_FILTER_BY_ID,
      REF_PRODUCT_FILTER_BY_L1_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L2_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L3_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L4_CATEGORIES, //
      REF_PRODUCT_SORT_BY,
    ),
    filter(() => isInPage("/matches/match", state$.value)),
    filter(() => {
      const {
        user: { user },
      } = state$.value

      return user !== undefined
    }),
    debounceTime(100),
    map((action) => withParent(action, listReferenceProducts(action.page))),
  )

const syncLocationParamsForMakeMatchesEpic = (action$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    filter(
      ({
        payload: {
          isFirstRendering = false,
          action,
          location: { pathname, state: { fromSso = false } = {} },
        },
      }) =>
        (isFirstRendering || action === "POP" || fromSso) &&
        pathname === "/matches/match",
    ),
    mergeMap((action) => {
      const {
        payload: {
          location: { search },
        },
      } = action
      const query = queryString.parse(search)

      const options = {
        fromUrl: true,
      }

      const routeProductId = query[QP_ID]

      if (routeProductId) {
        return of(
          withParent(action, filterReferenceProductsById(routeProductId, options)),
        )
      }

      return from(
        [
          filterReferenceProductsByCountry(forceArray(query[QP_COUNTRY]), options),
          filterReferenceProductsByFamily(forceArray(query[QP_FAMILY]), options),
          filterReferenceProductsByQuery(query[QP_QUERY], options),
          startFilterReferenceProductsByL1Categories(
            forceArray(query[QP_CATEGORY_1]),
            options,
          ),
          startFilterReferenceProductsByL2Categories(
            forceArray(query[QP_CATEGORY_2]),
            options,
          ),
          startFilterReferenceProductsByL3Categories(
            forceArray(query[QP_CATEGORY_3]),
            options,
          ),
          startFilterReferenceProductsByL4Categories(
            forceArray(query[QP_CATEGORY_4]),
            options,
          ),
          sortReferenceProductsBy(query[QP_SORT], options),
          selectReferenceProduct(+(query[QP_PAGE] || 1) - 1, options),
        ].map((newAction) => withParent(action, newAction)),
      )
    }),
  )

const onFilterReferenceProductByIdEpic = (action$) =>
  action$.pipe(
    ofType(REF_PRODUCT_FILTER_BY_ID),
    filter((action) => {
      const { options: { triggeredBy: { type } = {}, fromUrl } = {} } = action

      return (
        !fromUrl &&
        ![
          REF_PRODUCT_FILTER_BY_QUERY,
          REF_PRODUCT_FILTER_BY_COUNTRY,
          REF_PRODUCT_FILTER_BY_FAMILY,
          REF_PRODUCT_FILTER_BY_L1_CATEGORIES,
          REF_PRODUCT_FILTER_BY_L2_CATEGORIES,
          REF_PRODUCT_FILTER_BY_L3_CATEGORIES,
          REF_PRODUCT_FILTER_BY_L4_CATEGORIES,
        ].includes(type)
      )
    }),
    mergeMap((action) => {
      const options = {
        ...action.options,
        triggeredBy: action,
      }

      return from(
        [
          filterReferenceProductsByCountry(undefined, options),
          filterReferenceProductsByQuery(undefined, options),
          startFilterReferenceProductsByL1Categories(undefined, options),
          clearReferenceProductsList(),
        ].map((newAction) => withParent(action, newAction)),
      )
    }),
  )

const onFilterReferenceProductByNonIdFieldEpic = (action$) =>
  action$.pipe(
    ofType(
      REF_PRODUCT_FILTER_BY_QUERY,
      REF_PRODUCT_FILTER_BY_COUNTRY,
      REF_PRODUCT_FILTER_BY_FAMILY,
      REF_PRODUCT_FILTER_BY_L1_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L2_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L3_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L4_CATEGORIES,
    ),
    filter((action) => {
      const { options: { triggeredBy: { type } = {}, fromUrl } = {} } = action
      return !fromUrl && type !== REF_PRODUCT_FILTER_BY_ID
    }),
    debounceTime(100),
    mergeMap((action) =>
      from(
        [
          filterReferenceProductsById(undefined, {
            ...action.options,
            triggeredBy: action,
          }),
        ].map((newAction) => withParent(action, newAction)),
      ),
    ),
  )

const updateUrlFromStateEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    withLatestFrom(state$),
    filter(
      ([
        {
          payload: {
            action,
            location: { pathname, state: { fromSso = false } = {} },
          },
        },
        {
          app: {
            prevLocation: { pathname: currentPathname },
          },
        },
      ]) =>
        (action === "PUSH" || action === "REPLACE") &&
        pathname === "/matches/match" &&
        pathname !== currentPathname &&
        !fromSso,
    ),
    map(([action]) => withParent(action, urlActions.updateUrlFromState())),
  )

const updateLocationForMakeMatchesEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      REF_PRODUCT_FILTER_BY_QUERY,
      REF_PRODUCT_FILTER_BY_COUNTRY,
      REF_PRODUCT_FILTER_BY_FAMILY,
      REF_PRODUCT_FILTER_BY_L1_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L2_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L3_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L4_CATEGORIES,
      REF_PRODUCT_SORT_BY,
      REF_PRODUCT_SELECT,
    ),
    filter(({ options: { fromUrl } = {} }) => !fromUrl),
    filter(() => isInPage("/matches/match", state$.value)),
    debounceTime(100),
    map(({ type }) => {
      const {
        router: { location },
        refProduct: {
          selectedCountries,
          selectedFamilies,
          referenceProductsQuery,
          selectedL1Categories,
          selectedL2Categories,
          selectedL3Categories,
          selectedL4Categories,
          detailedProductIndex,
          selectedProductId,
          sortBy,
          source,
        },
      } = state$.value

      const replaceActions = [
        urlActionTypes.URL_UPDATE_FROM_STATE,
        REF_PRODUCT_CLEAR_LIST,
        REF_PRODUCT_FILTER_BY_ID,
      ]

      const historyAction = replaceActions.includes(type) ? replace : push

      return historyAction(
        updateLocationQuery(location, {
          [QP_QUERY]: referenceProductsQuery,
          [QP_CATEGORY_1]: selectedL1Categories,
          [QP_CATEGORY_2]: selectedL2Categories,
          [QP_CATEGORY_3]: selectedL3Categories,
          [QP_CATEGORY_4]: selectedL4Categories,
          [QP_COUNTRY]: selectedCountries,
          [QP_FAMILY]: selectedFamilies,
          [QP_SORT]: sortBy,
          [QP_PAGE]: detailedProductIndex ? detailedProductIndex + 1 : undefined,
          [QP_ID]: selectedProductId,
          [QP_MODE]: source,
        }),
      )
    }),
  )

const updateLocationReplaceForMakeMatchesEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      REF_PRODUCT_FILTER_BY_ID,
      REF_PRODUCT_CLEAR_LIST,
      urlActionTypes.URL_UPDATE_FROM_STATE,
    ),
    filter(({ options: { fromUrl } = {} }) => !fromUrl),
    filter(() => isInPage("/matches/match", state$.value)),
    debounceTime(100),
    map(({ type }) => {
      const {
        router: { location },
        refProduct: {
          selectedCountries,
          selectedFamilies,
          referenceProductsQuery,
          selectedL1Categories,
          selectedL2Categories,
          selectedL3Categories,
          selectedL4Categories,
          detailedProductIndex,
          selectedProductId,
          sortBy,
        },
      } = state$.value

      const replaceActions = [
        urlActionTypes.URL_UPDATE_FROM_STATE,
        REF_PRODUCT_CLEAR_LIST,
        REF_PRODUCT_FILTER_BY_ID,
      ]

      const historyAction = replaceActions.includes(type) ? replace : push

      return historyAction(
        updateLocationQuery(location, {
          [QP_QUERY]: referenceProductsQuery,
          [QP_CATEGORY_1]: selectedL1Categories,
          [QP_CATEGORY_2]: selectedL2Categories,
          [QP_CATEGORY_3]: selectedL3Categories,
          [QP_CATEGORY_4]: selectedL4Categories,
          [QP_COUNTRY]: selectedCountries,
          [QP_FAMILY]: selectedFamilies,
          [QP_SORT]: sortBy,
          [QP_PAGE]: detailedProductIndex ? detailedProductIndex + 1 : undefined,
          [QP_ID]: selectedProductId,
        }),
      )
    }),
  )

const triggerClearReferenceProductListEpic = (action$) =>
  action$.pipe(
    ofType(
      REF_PRODUCT_FILTER_BY_QUERY,
      REF_PRODUCT_FILTER_BY_COUNTRY,
      REF_PRODUCT_FILTER_BY_FAMILY,
      REF_PRODUCT_FILTER_BY_ID,
      REF_PRODUCT_FILTER_BY_L1_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L2_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L3_CATEGORIES,
      REF_PRODUCT_FILTER_BY_L4_CATEGORIES,
      REF_PRODUCT_SORT_BY,
    ),
    filter((action) => {
      const { options: { triggeredBy: { type } = {}, fromUrl } = {} } = action
      return !fromUrl && type !== REF_PRODUCT_FILTER_BY_ID
    }),
    debounceTime(100),
    map((action) => withParent(action, clearReferenceProductsList())),
  )

export default [
  triggerClearReferenceProductListEpic,
  getReferenceProductsSummaryEpic,
  getReferenceProductsCategoriesEpic,
  listReferenceProductsEpic,
  listReferenceProductAutoCompleteSuggestionsEpic,
  startFilterReferenceProductByL1CategoriesEpic,
  startFilterReferenceProductByL2CategoriesEpic,
  startFilterReferenceProductByL3CategoriesEpic,
  startFilterReferenceProductByL4CategoriesEpic,
  updateReferenceProductsL2CategoriesEpic,
  updateReferenceProductsL3CategoriesEpic,
  updateReferenceProductsL4CategoriesEpic,
  getProductsByIdsEpic,
  loadImagesAfterGetByIdsSuccessEpic,
  listExistingMatchesEpic,
  loadInfoAfterGetMatchesSuccessEpic,
  syncLocationParamsForMakeMatchesEpic,
  updateUrlFromStateEpic,
  updateLocationForMakeMatchesEpic,
  updateLocationReplaceForMakeMatchesEpic,
  onFilterReferenceProductByIdEpic,
  onFilterReferenceProductByNonIdFieldEpic,
  startSelectReferenceProductEpic,
  cancelQueryFilteringEpic,
  triggerListReferenceProductsEpic,
  triggerStartSelectProductAfterListReferenceProductsEpic,
  filterReferenceProductsL2CategoriesAfterUpdateEpic,
  filterReferenceProductsL3CategoriesAfterUpdateEpic,
  filterReferenceProductsL4CategoriesAfterUpdateEpic,
]
