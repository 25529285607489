import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Badge, List, ListItem, ListItemButton, Typography } from "@mui/material"
import { envLabel } from "../../util/env"
import "./nav-logo.scss"

function NavLogo({ title, mini, onClick }) {
  const label = useMemo(() => envLabel(), [])
  const titleEl = (
    <span>
      {title && label && (
        <Badge
          classes={{ badge: "badge-environment" }}
          badgeContent={
            <Typography
              variant="caption"
              sx={{
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              {label}
            </Typography>
          }
          color="secondary"
          sx={{
            verticalAlign: "baseline",
          }}
        >
          {title || " "}
        </Badge>
      )}
      {title && !label && <span>{title || " "}</span>}
    </span>
  )

  return (
    <List component="nav">
      <ListItem disablePadding>
        <ListItemButton className="logo" onClick={onClick} sx={{ height: "5.5em" }}>
          {mini && label && (
            <Badge color="secondary" sx={{ top: "-15px" }} variant="dot" />
          )}
          {!mini && <Typography variant="h1">{titleEl}</Typography>}
        </ListItemButton>
      </ListItem>
    </List>
  )
}

NavLogo.propTypes = {
  title: PropTypes.string,
  mini: PropTypes.bool,
  onClick: PropTypes.func,
}

NavLogo.defaultProps = {
  title: "Daltix",
  mini: false,
  onClick: () => {},
}

export { NavLogo }
