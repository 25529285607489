import React, { useEffect, useMemo, useState } from "react"
import {
  Autocomplete,
  Box,
  debounce,
  Divider,
  FormControl,
  Icon,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { func, string } from "prop-types"
import { useTranslation } from "react-i18next"
import { getRefProdAutoCompleteSuggestions } from "../../api/service"

export const refProductSourceFields = {
  all: "all",
  articleNr: "articlenr",
  brand: "brand",
  eans: "ean",
  name: "name",
}

function ReferenceProductSearch({
  className,
  filters,
  onChange,
  onSelect,
  sx,
  value,
  ...rest
}) {
  const theme = useTheme()
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState(value || null)
  const [selected, setSelected] = useState(null)
  const [suggestions, setSuggestions] = useState([])
  const [searchMode, setSearchMode] = useState(refProductSourceFields.all)
  const [oldMode, setOldMode] = useState(refProductSourceFields.all)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const selectOptions = useMemo(
    () => ({
      [refProductSourceFields.all]: t("All"),
      [refProductSourceFields.brand]: t("Brand"),
      [refProductSourceFields.name]: t("Name"),
      [refProductSourceFields.eans]: t("EAN"),
      [refProductSourceFields.articleNr]: t("Article No."),
    }),
    [t],
  )

  const getRefProductsSuggestions = useMemo(() => {
    async function fetch(query) {
      const {
        products: { articleNrs, brands, eans, names },
      } = await getRefProdAutoCompleteSuggestions({
        limit: 10,
        query,
        source: searchMode,
        ...filters,
      })

      const newSuggestions = [
        ...articleNrs.map((v) => ({
          mode: refProductSourceFields.articleNr,
          search: v,
          id: `ref_${v}`,
          isSuggestion: true,
        })),
        ...eans.map((v) => ({
          mode: refProductSourceFields.eans,
          search: v,
          id: `ean_${v}`,
          isSuggestion: true,
        })),
        ...names.map((v) => ({
          mode: refProductSourceFields.name,
          search: v,
          id: `name_${v}`,
          isSuggestion: true,
        })),
        ...brands.map((v) => ({
          mode: refProductSourceFields.brand,
          search: v,
          id: `brand_${v}`,
          isSuggestion: true,
        })),
      ]

      newSuggestions.sort((a, b) => a.search.localeCompare(b.search))

      const selectSuggestions = Object.keys(selectOptions)
        .map((key) => ({
          mode: key,
          search: query,
          id: `mode_${key}_${query}`,
          isSuggestion: false,
        }))
        .filter((suggestion) => {
          if (
            suggestion.mode === refProductSourceFields.eans &&
            !/^\d+$/.test(searchQuery) &&
            searchQuery
          ) {
            return false
          }
          return true
        })

      const allSuggestions = [...selectSuggestions, ...newSuggestions]

      setLoading(false)
      setSuggestions(allSuggestions)
    }

    return debounce(fetch, 400)
  }, [filters, searchMode, searchQuery, selectOptions])

  useEffect(() => {
    if (!value || value === "") {
      setSelected(null)
      setSearchQuery(value)
    }
  }, [value])

  useEffect(() => {
    if (!searchQuery) {
      return undefined
    }

    return getRefProductsSuggestions(searchQuery)
  }, [getRefProductsSuggestions, searchQuery])

  const closePopper = () => setOpen(false)
  const openPopper = () => setOpen(true)

  const handleModeChange = (e) => {
    const { value: mode } = e.target
    setSearchMode(mode)
    if (searchQuery) {
      getRefProductsSuggestions(searchQuery)
    }
    if (selected || searchQuery) {
      onSelect({
        query: selected?.search || searchQuery,
        source: mode,
        modeChanged: true,
      })
    }
  }

  const onSelectOption = (_, newValue) => {
    setSelected(newValue)
    setSearchQuery(newValue.search || searchQuery)
    let source = newValue.mode
    const brandSuggestion =
      newValue.isSuggestion && newValue.mode === refProductSourceFields.brand
    if (brandSuggestion || !newValue.isSuggestion) {
      setSearchMode(newValue.mode)
    } else {
      source = searchMode
      setSearchMode(searchMode)
    }
    onSelect({ query: newValue.search, source, modeChanged: source !== oldMode })
    setOldMode(source)
  }

  const handleEnterPressed = (e) => {
    if (e.key === "Enter" && e.target.value?.length >= 0) {
      onSelectOption(e, {
        search: e.target.value,
        mode: searchMode,
        isSuggestion: false,
        id: `input_${searchMode}_${e.target.value}`,
      })
      closePopper()
    }
  }

  const onInputChange = (_, newValue, reason) => {
    if (reason !== "input") {
      return
    }
    getRefProductsSuggestions.clear()

    setSearchQuery(newValue)
    onChange(_, { newValue, method: reason })
    setLoading(() => {
      if (newValue.length === 0) {
        return false
      }
      return true
    })
  }

  const handleShowTooltip = (event) => {
    const { currentTarget } = event
    if (currentTarget.offsetWidth < currentTarget.scrollWidth) {
      setAnchorEl(currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleHideTooltip = () => {
    setAnchorEl(null)
  }

  const renderCheck = (show) => {
    let icon = " "
    if (show) {
      icon = (
        <Icon
          className="material-symbols-outlined"
          sx={{ color: theme.palette.common.black, opacity: 0.56 }}
        >
          check
        </Icon>
      )
    }
    return (
      <Box sx={{ alignItems: "center", display: "flex", minWidth: 36 }}>{icon}</Box>
    )
  }

  const formatOptionText = (search, isSuggestion) => {
    const result = []
    if (isSuggestion) {
      const regEscape = (v) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
      const regexSplit = new RegExp(regEscape(searchQuery), "ig")
      const parts = search.split(regexSplit)
      const regexp = new RegExp(`${searchQuery}`, "gi")
      const matches = [...search.matchAll(regexp)]
      let idx = 0

      parts.forEach((p, i) => {
        const key = `${p}_${i}`
        result.push(
          <Typography key={key} variant="body1" fontWeight={700}>
            {p}
          </Typography>,
        )
        if (i < parts.length - 1) {
          result.push(
            <Typography key={`${key}_query`} variant="body1">
              {matches[idx]}
            </Typography>,
          )
          idx += 1
        }
      })
    } else {
      result.push(
        <Typography key={`${search}_query`} variant="body1">
          {searchQuery}
        </Typography>,
      )
    }

    return (
      <Box
        aria-haspopup="true"
        onMouseEnter={handleShowTooltip}
        onMouseLeave={handleHideTooltip}
        sx={{
          alignItems: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          "> p": {
            display: "inline",
          },
        }}
      >
        <Tooltip
          arrow={false}
          disableInteractive
          placement="bottom"
          title={anchorEl ? <Typography variant="subtitle2">{search}</Typography> : ""}
        >
          {result}
        </Tooltip>
      </Box>
    )
  }

  const renderOptions = (props, option) => {
    let icon = ""
    let search = ""
    let where = ""

    if (option.mode && !option.isSuggestion) {
      icon = "search"
      where = selectOptions[option.mode]
    }
    if (option.isSuggestion) {
      icon = "arrow_right_alt"
      if (option.mode === refProductSourceFields.brand) {
        where = selectOptions[option.mode]
      }
    }

    if (option.search) {
      search = option.search
    }

    const formattedSearch = formatOptionText(search, option.isSuggestion)

    return (
      <>
        <li {...props} key={`${option.mode}_${option.search}`}>
          {icon && (
            <Box sx={{ pr: 1 }}>
              <Icon className="material-symbols-outlined">{icon}</Icon>
            </Box>
          )}
          {formattedSearch}
          {where && option.mode !== refProductSourceFields.all && (
            <Box sx={{ pl: 1 }}>
              <Typography variant="caption">
                {t("in")} {where}
              </Typography>
            </Box>
          )}
        </li>
        {option.mode === refProductSourceFields.articleNr && !option.isSuggestion && (
          <Divider sx={{ mx: 1 }} />
        )}
      </>
    )
  }

  const renderInput = (params) => (
    <TextField
      {...params}
      fullWidth
      id="reference-product-search-input"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <Box
            {...params.InputProps.endAdornment.props}
            sx={{
              margin: "-6px -6px -6px 0px",
            }}
          >
            <FormControl size="small">
              <Select
                disableUnderline
                IconComponent={(props) => (
                  <Icon
                    {...props}
                    // eslint-disable-next-line react/prop-types
                    className={`material-symbols-outlined ${props.className}`}
                  >
                    keyboard_arrow_down
                  </Icon>
                )}
                id="ref-prod-options-select"
                inputProps={{
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        backgroundColor: theme.palette.grey[100],
                      },
                    },
                  },
                }}
                value={searchMode}
                renderValue={(val) => selectOptions[val]}
                onChange={handleModeChange}
                size="small"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.02)",
                  border: 0,
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                  boxShadow: "none",
                  outline: "none",
                  p: "0 !important",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.08)",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.08)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.08)",
                  },
                }}
              >
                {Object.keys(selectOptions)
                  .map((opt) => {
                    if (
                      searchMode !== refProductSourceFields.eans &&
                      opt === refProductSourceFields.eans &&
                      !/^\d+$/.test(searchQuery) &&
                      searchQuery
                    ) {
                      return null
                    }
                    return (
                      <MenuItem value={opt} key={opt}>
                        {renderCheck(searchMode === opt)} {selectOptions[opt]}
                      </MenuItem>
                    )
                  })
                  .filter((s) => s)}
              </Select>
            </FormControl>
          </Box>
        ),
        startAdornment: (
          <>
            <InputAdornment position="start">
              <Icon className="material-symbols-outlined">search</Icon>
            </InputAdornment>
          </>
        ),
      }}
      label={t("Search")}
      size="small"
      variant="outlined"
      sx={{
        borderColor: theme.palette.divider,
      }}
    />
  )
  const showNoOptionsText = () =>
    searchQuery && suggestions.length === 0
      ? t("No reference products")
      : t("Type to search")

  return (
    <Autocomplete
      {...rest}
      data-testid="daltix-reference-product-search-autocomplete"
      autoComplete
      className={className}
      disableClearable
      filterSelectedOptions
      filterOptions={(x) => x}
      getOptionDisabled={(option) => option?.id === selected?.id}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option?.search || ""
      }
      id="reference-product-search"
      inputValue={searchQuery}
      isOptionEqualToValue={(option, optSelected) => option.id === optSelected.id}
      noOptionsText={loading ? <LinearProgress /> : showNoOptionsText()}
      open={open}
      onOpen={openPopper}
      onClose={closePopper}
      onChange={onSelectOption}
      onInputChange={onInputChange}
      onKeyUp={handleEnterPressed}
      options={searchQuery === "" || loading ? [] : suggestions}
      renderInput={renderInput}
      renderOption={renderOptions}
      size="small"
      sx={{
        width: 320,
        "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
          paddingRight: "6px !important",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          border: "1px solid rgba(0, 0, 0, 0.08)",
        },
        ...sx,
      }}
      value={selected}
    />
  )
}

ReferenceProductSearch.propTypes = {
  className: string,
  filters: {},
  onChange: func,
  onSelect: func,
  sx: {},
  value: string,
}

ReferenceProductSearch.defaultProps = {
  className: "",
  filters: {},
  onChange: () => {},
  onSelect: () => {},
  sx: {},
  value: null,
}

export { ReferenceProductSearch }
